<template>
  <v-card :loading="loading" :style="`background: ${$vuetify.theme.dark ? '' : '#e4e4e4'};`">
    <v-container>
      <v-row>
        <v-col cols="12" sm="4">
          <v-card outlined class=" mb-3">
            <v-card-title class="primary--text">{{ $t('contratos.estado') }}</v-card-title>
            <v-divider></v-divider>
            <div class="pa-3">
              <div class="d-flex">
                <EstadoContrato x-large label class="flex-grow-1 text-center mt-4" :estado="contrato.estado" />
              </div>
              <h3 class="pl-2 mt-2">{{ contrato.explicacion_estado }}</h3>
              <template v-if="contrato.estado == 'DEVOLVIENDO' ||
    contrato.estado == 'DEVUELTO'
    ">
                <br />{{ $t('contratos.motivo_dev') }}:<br />
                <b>{{ contrato.motivodevolucion || contrato.comentario }}</b><br />
              </template>
              <template v-if="contrato.estado == 'ANULADO'">
                <br />{{ $t('contratos.motivo_anu') }}:<br />
                <b>{{ contrato.motivoanulacion || contrato.comentario }}</b><br />
              </template>
              <template v-if="contrato.estado == 'DETENIDO'">
                <br />{{ $t('contratos.motivo_det') }}:<br />
                <b>{{ contrato.motivodetencion || contrato.comentario }}</b><br />
              </template>
              <template v-if="contrato.numerofactura && $root.acceso('FACTURAS')">
                <strong>{{ $t('contratos.factura') }}:</strong>
                <v-divider class="mt-1 mb-2"></v-divider>
                <div class="d-flex" style="gap: 10px">
                  <span>Nº: </span> <strong>{{ contrato.numerofactura }}</strong>
                </div>
                <div class="d-flex" style="gap: 10px">
                  <span>{{ $t('contratos.fecha') }}: </span>
                  <strong>{{ parseDate(contrato.fechafactura) }}</strong>
                </div>
                <div class="d-flex" style="gap: 10px">
                  <span>{{ $t('contratos.bimp') }}: </span> <strong>{{ contrato.bifactura }}</strong>
                </div>
              </template>
              <!-- <v-btn class="mt-2" block v-if="$root.modules('TICKETING') && !contrato.refticket " color="primary"
                @click.stop="openTicket">
                <v-icon left>mdi-ticket</v-icon>
                Abrir consulta
              </v-btn>
              <v-btn v-else-if="$root.modules('TICKETING') && contrato.refticket" class="mt-2" block color="primary" :to="`/tickets/${ contrato.refticket }`" >
                <v-icon left>mdi-ticket</v-icon>
                Ver consulta
              </v-btn> -->
              <div class="mt-4">
                <v-btn v-if="contrato.archivo" block @click.stop="verDocumento" color="primary">
                  <v-icon left>mdi-text-box</v-icon>
                  {{ $t('contratos.ver_doc') }}
                </v-btn>
                <v-btn class="mt-2" block :to="`/facturas?_detailsId=${contrato.refpago}`"
                  v-if="contrato.refpago && $root.modules('FACTURAS') && $root.acceso('FACTURAS')" color="primary">
                  <v-icon left>mdi-text-box</v-icon>
                  {{ $t('contratos.ver_fac') }}
                </v-btn>
              </div>

              <div class="mt-4" v-if="estados_anulables.includes( contrato.estado )" >
                <v-btn class="mt-2" block @click.stop="anularContrato" color="primary">
                  <v-icon left>mdi-close-octagon</v-icon>
                  {{ $t('contratos.anular') }}
                </v-btn>
              </div>

            </div>
          </v-card>
        </v-col>

        <v-col cols="12" sm="8">
          <v-card outlined class="mb-3">
            <v-card-title class="primary--text">{{ $t('contratos.datos') }}</v-card-title>
            <v-divider></v-divider>
            <div class="pa-3">
              <InfoInput :title="$t('borradores.comer')" v-if="contrato.comercializadora"
                :value="contrato.comercializadora">
              </InfoInput>
              <InfoInput :title="$t('borradores.tarifa')" :value="contrato.descTarifa"></InfoInput>
              <InfoInput :title="$t('borradores.cups')" :value="contrato.cups"></InfoInput>
              <InfoInput :title="$t('borradores.nombre_titular')" :value="contrato.cliente"></InfoInput>
              <InfoInput :title="$t('borradores.dni_titular')" :value="contrato.nif_cif_titular"></InfoInput>
              <InfoInput :title="$t('borradores.dir_cups')" :value="contrato.direccion_punto_suministro"></InfoInput>
              <InfoInput :title="$t('borradores.email')" :value="contrato.email"></InfoInput>
              <InfoInput :title="$t('borradores.telf')" :value="[contrato.telefono1, contrato.telefono2].filter(x => !!x).join(' // ')"></InfoInput>
              <InfoInput :title="$t('contratos.comentario')" v-if="contrato.comentario" :value="contrato.comentario">
              </InfoInput>
              <InfoInput :title="$t('borradores.otros')" v-if="contrato.otrosdatos" :value="contrato.otrosdatos">
              </InfoInput>

              <InfoInput :title="$t('contratos.agente')" v-if="contrato.refagente" :value="contrato.nombreagente">
              </InfoInput>
            </div>

          </v-card>
        </v-col>

        <!-- <v-col cols="12" sm="4" v-if="$root.modules('VERIFICACIONES')">
          <SubidaVerificacion @reload="getContrato" :contrato="contrato" />
        </v-col> -->

        <v-col cols="12" md="8" v-if="contrato.documentos">
          <DocumentosContrato @uploaded="getContrato" :refcontrato="contrato.ref" :docs="contrato.documentos" />
        </v-col>

        <v-col cols="12" md="4">
          <v-card outlined class="mb-3">
            <v-card-title class="primary--text">{{ $t('contratos.historico') }}</v-card-title>
            <v-divider class="mb-4"></v-divider>

            <v-timeline dense>
              <v-timeline-item v-for="hist in contrato.historico" :key="hist.Id">
                <v-card class="pa-2">
                  <v-card-subtitle class="pa-0">{{ parseDate(hist.fecha, false, true, true) }} -
                    {{ hist.nombreadministrador }}</v-card-subtitle>
                  <v-card-title class="pa-0">{{ hist.asunto }}</v-card-title>
                  <v-card-text class="pa-0" v-if="hist.comentario">{{
    hist.comentario
                    }}</v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-card>
        </v-col>
        <!-- <v-col cols="12" md="6">
          <Chat :refcontrato="detailsId" />
        </v-col> -->

      </v-row>
    </v-container>
    <ConfirmDialog ref="confirm" />
  </v-card>
</template>

<script>
import { parseDate, downloadFile } from "@/utils/index.js";

export default {
  props: {
    detailsId: Number | String,
  },
  components: {
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
    Chat: () => import("../components/Chat/Chat.vue"),
    EstadoContrato: () => import("../components/EstadoContrato.vue"),
    InfoInput: () => import("../components/InfoInput.vue"),
    SubidaVerificacion: () => import("../components/SubidaVerificacion.vue"),
    DocumentosContrato: () => import("../components/DocumentosContrato.vue"),
  },
  data() {
    return {
      _contrato: {},
      documentoVerificacion: null,
      estados_anulables: ["PENDIENTE", "TRAMITADO", "PDTE.FIRMA"],
      loading: true
    };
  },
  computed: {
    contrato() {
      return !this.loading ? new Proxy(this._contrato, {
        get: function (target, key) {
          if (typeof key == "string") {
            var uKey = key.toUpperCase();

            if ((key != uKey) && (key in target))
              return target[key];
            return target[uKey];
          }
          return target[key];
        }
      }) : {}
    }
  },
  methods: {
    parseDate,
    async getContrato() {
      if (!this.detailsId) return;
      this.loading = true
      const { data } = await axios({
        method: "GET",
        url: `/contratos/${this.detailsId}`,
      });
      this._contrato = data;
      this.loading = false;
    },
    async verDocumento() {
      try {
        const { data } = await axios({
          method: "GET",
          url: `/contratos/documento/${this.contrato.archivo}`,
          responseType: "blob",
        });

        downloadFile(data, true);
      } catch {
        this.$root.$emit("snack", this.$t('documentacion.not_found'));
      }
    },
    async openTicket() {
      const { data } = await axios({
        method: "POST",
        url: `/tickets`,
        data: {
          refcontrato: this.contrato.ref,
          tipo: 'ASISTENCIA SOBRE CONTRATO'
        }
      })

      this.$router.push(`/tickets/${data.idTicket}`)
    },
    async anularContrato() {

      const confirm = await this.$refs.confirm.open(
        this.$t('confirm.anular.title'),
        this.$t('confirm.anular.text'),
        { color: "error darken-2", confirmColor: "error darken-2", addExplanation: true }
      )

      if (!confirm)
        return;
      try {
        const { data } = await axios({
          method: "PUT",
          url: `/contratos/anular/${this.contrato.ref}`,
          data: {
            motivo: confirm
          }
        })
        this.$root.$emit("snack", this.$t('confirm.anular.ok_result'));
        this.getContrato();
      } catch {
        this.$root.$emit("snack", this.$t('confirm.anular.error_result'));
      }
    },
    },
    mounted() {
      this.getContrato();
    },
  };
</script>

<style lang="scss" scoped>
.title {
  color: var(--v-primary-base);
  font-size: 1.6rem !important;
}
</style>
